import { Navbar , Nav, Container,NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, } from 'react'
import './Navbar.css';
import log1 from "./Assets/logo.png";


function Navigation() {



  const [show, setShow] = useState(false);
  const [button,setButton] = useState(true);


   

  const showButton = () =>{
    if(window.innerWidth <= 960){
      setButton(false);
      setNavbar(true);
    }
    else{
      setButton(true);
    }
  }
  
  const showDropdown = (e)=>{
      setShow(!show);
  }
  const hideDropdown = e => {
      setShow(false);
  }

const [navbar,setNavbar] = useState(false);

  const changeBackground = () =>{
    if(button){
    if(window.scrollY>=80){
      setNavbar(true);
    }
    else{
      setNavbar(false);
    }
  }
  else{
    setNavbar(true);
  }
  }
  useEffect(()=>{
    showButton();
  },[]);

  window.addEventListener('resize',showButton);

window.addEventListener('scroll',changeBackground);

return(
<Navbar style={{backgroundColor:'#f9f5ef',transition:'1000ms',}} collapseOnSelect expand="lg" variant='light' fixed='top'>
  <Navbar.Brand   href="/">   
  <img alt='Brown Profiles Logo' style={{height:50}} src={log1}></img>
 </Navbar.Brand>

  <Navbar.Toggle aria-controls="responsie-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end" style={{paddingRight:50}}>

    <Nav   style={{}}>
  

    <Nav.Link className='navLink'  style={{marginRight:20}} href="/">HOME</Nav.Link>
    <Nav.Link className='navLink'  style={{marginRight:20}} onClick={()=>window.open('https://drive.google.com/file/d/1S_clEQib4J7od7yKe-oVNVSjqDTfDZpV/view')}>BROCHURE</Nav.Link>
    <Nav.Link className='navLink'  style={{marginRight:20}} href="/fluted">FLUTED</Nav.Link>
    <Nav.Link className='navLink'  style={{marginRight:20}} href="/price">PRICING</Nav.Link>
      <Nav.Link className='navLink'  style={{marginRight:20}} href="/about">ABOUT US</Nav.Link>
      <Nav.Link className='navLink' eventKey={2} href="/contact">
        CONTACT US
      </Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
);
} 

export default Navigation;