
import '../App.css';
import React, { useState, useEffect, } from 'react'
import bg from "../Assets/bg3.jpg";
import bg10 from "../Assets/32.jpeg";
import bg11 from "../Assets/11.jpg";
import bg12 from "../Assets/12.jpg";
import bg13 from "../Assets/33.jpeg";
import logo from "../Assets/logo2.png";
import connect from "../Assets/Connect.png";
import { Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Aos from 'aos';
import { BsArrowRight } from "react-icons/bs";
import Carousel, { slidesToShowPlugin,arrowsPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import 'aos/dist/aos.css'; 
import  Footer  from "../Footer.js";


function Home() {

    const [button,setButton] = useState(true);

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);


  
  useEffect(()=>{
    Aos.init({duration:1000});
  },[]);



  return (
    
    <div  style={{backgroundColor:"#f9f5ef",}}>

          <title>Brown Profiles | The modern wall luxury panel | Fluted wooden panels</title>
        <div  className='bg' style={{display:'flex',alignItems:'center',}}>
      

      <div  style={{display:'flex',alignContent:'center',flexDirection:'column',color:'white',paddingLeft:button?50:20,width:button?'55vw':'90vw',fontFamily:'sans-serif',paddingTop:100}}>
        <h1 style={{fontWeight:'bold',paddingBottom:15,}}>Building Wooden Luxury</h1>
        <text style={{paddingBottom:20,fontWeight:500,fontSize:'x-large',color:'white'}}>Perfectly curated fluted wooden panels to make your space luxurious and worthy.</text>
        <Button href='/about' style={{borderRadius:0,width:200,marginTop:20}} size='lg' variant="light">Learn More</Button> 
 
      </div>
    </div>
 <div data-aos="fade-up"   style={{fontFamily:'Canela',padding:button?100:40,marginTop:50,marginBottom:50}}>
      <text style={{fontSize:button?'xxx-large':'xx-large',color:'#1d1c18',fontWeight:300,}}>Brown Profile provides designer wooden panels curated with international quality and design standards to give a luxurious feel to your space.</text>
    </div>
  

    <div style={{display:'flex',marginBottom:button?50:25,flexDirection:button?'row':'column-reverse',backgroundColor:"#1e1c21"}}>
      <div data-aos="fade-right"   data-aos-easing="ease-in-sine" style={{width:button?'50%':'100%',display:'flex',flexDirection:'column',justifyContent:'center',padding:'5vw',fontFamily:'Montserrat',paddingBottom:button?null:50}}>
          <text style={{fontWeight:'bold',paddingBottom:'2vw',color:'wheat'}}>History and Mission</text>
          <text style={{fontSize:button?'4vh':'4vw',fontFamily:'Canela',color:'whitesmoke'}}>Working as an interior designer and ending up with limited wall paneling sources, lead us to the foundation of Brown Profiles to help like-minded designers and people looking for the perfect wooden paneling sources.</text>
          <div style={{display:'flex',marginTop:'2vw'}}>
  <a href='/about' className='underline-hover-effect' style={{fontSize:14,color:'#C19A5B'}}>OUR BACKGROUND</a>
  <BsArrowRight style={{width:20,height:20,marginLeft:10,color:'#C19A5B'}}></BsArrowRight>
  </div>
      </div>

      <img alt='Brown Profiles fluted wooden panelling on wall' data-aos="fade-up"   data-aos-easing="linear"
 style={{height:button?'100vh':'50vh',width:button?'50%':'100%',marginTop:button?0:50,marginBottom:button?0:25}} src={bg}/>
    </div>

    <div data-aos="fade-up"  style={{alignItems:'center',display:'flex',flexDirection:'column',lineHeight:0.75,fontFamily:'Cormorant',padding:button?100:50,}}>
  <text style={{fontSize:button?50:40,}}>TIMELESS</text>
  <text style={{fontSize:button?50:40,}}>COLLECTION</text>
<text style={{fontFamily:'montserrat',paddingTop:25,letterSpacing:3,fontSize:10}}> MODERN  WOOD  MINIMAL</text>
</div>

    <Carousel  data-aos="fade-up"
    plugins={[
      'infinite',
      'fastSwipe',
    {
      resolve: slidesToShowPlugin,
     

      options: {

       numberOfSlides:button?3.3:1,
      },
    },
  ]}   
>

  <div className={button?'easton':'eastonm'}>
  <text>EASTON</text>
  </div>
  <div className={button?'oxford':'oxfordm'}>
  <text>OXFORD</text>
  </div>
  <div className={button?'delta':'deltam'}>
  <text>DELTA</text>
  </div>
  <div className={button?'zion':'zionm'}>
  <text>ZION</text>
  </div>
  <div className={button?'copper':'copperm'}>
  <text>COPPER</text>
  </div>
 
  <div className={button?'edison':'edisonm'}>
  <text>EDISON</text>
  </div>
 
  <div className={button?'cove':'covem'}>
  <text>COVE</text>
  </div>
  <div className={button?'bianca':'biancam'}>
    <text>BIANCA</text>
  </div>
  <div className={button?'oakley':'oakleym'}>
  <text>OAKLEY</text>
  </div>
  <div className={button?'sacha':'sacham'}>
  <text>SACHA</text>
  </div>
  <div className={button?'brentley':'brentleym'}>
  <text>BRENTLEY</text>
  </div>
  <div className={button?'sorrento':'sorrento'}>
  <text>SORRENTO</text>
  </div>
  <div className={button?'chevron':'chevron'}>
  <text>CHEVRON</text>
  </div>
  <div className={button?'sublime':'sublime'}>
  <text>SUBLIME</text>
  </div>

 
 {/*  <img alt='Brown profiles wooden panel' style={{height:'20vw',width:'25vw'}} src={bg8} />*/}

</Carousel>

<div style={{backgroundColor:'#1e1c21',color:'white',paddingTop:100,paddingBottom:100,marginTop:200,fontFamily:'Montserrat',}}>
<div style={{textAlign:'center',display:'flex',flexDirection:'column',alignItems:'center'}}>
    <h4 style={{width:'90%',letterSpacing:5}}>MORE DESIGN SOLUTIONS</h4>
    <text style={{width:button?'60%':'80%',paddingTop:10,paddingBottom:75,color:'grey',fontSize:14}}>Over the years, we are known for creating interiors with a difference. Our design solutions extend to any possible spaces within the home embracing unique craftsmanship and bespoke detailing.</text>
    </div>

    <div style={{display:'flex',flexDirection:button?'row':'column',justifyContent:'space-evenly',paddingBottom:20}}>
      <div data-aos="zoom-in" style={{textAlign:'center',padding:25}}>
        <img alt='Fluted wooden panel wardrobe'  style={{width:button?'22vw':'50vw',height:button?'22vw':'50vw'}} src={bg12}/>
        <h5 style={{paddingTop:25,letterSpacing:3}}>WARDROBES</h5>
        <p style={{fontSize:12,color:'grey',width:button?'25vw':null}}>We create wardrobes that are sleek and effortlessly stylish. The quality
is unmatched.</p>
      </div>
      <div data-aos="zoom-in" style={{textAlign:'center',padding:25}}>
      <img alt='Fluted wooden panel wall'  style={{width:button?'22vw':'50vw',height:button?'22vw':'50vw'}} src={bg10}/>
        <h5 style={{paddingTop:25,letterSpacing:3}}>LIVING ROOMS</h5>
        <p style={{fontSize:'small',color:'grey',fontSize:12,width:button?'25vw':null}}>The living room is the center of your home. We bring it to life.</p>
      </div>
      <div  data-aos="zoom-in" style={{textAlign:'center',padding:25}}>
      <img alt='Fluted wooden panel vanaties'  style={{width:button?'22vw':'50vw',height:button?'22vw':'50vw'}} src={bg13}/>
        <h5 style={{paddingTop:25,letterSpacing:3}}>BEDROOMS</h5>
        <p style={{fontSize:'small',color:'grey',fontSize:12,width:button?'25vw':null}}>Get your bedroom interiors crafted just the way you want, with our timeless range of fluted wooden panels.</p>
      </div>
    </div>
  </div>


    <div data-aos="fade-up" style={{fontFamily:'Canela',padding:'20vh',display:'flex',flexDirection:'column',alignItems:'center',textAlign:'center'}}>
      <img alt='Brown Profiles Logo' style={{height:60,width:60,marginBottom:50}} src={logo}/>
      <text style={{fontSize:'large',color:'#1d1c18',fontWeight:300,width:button?'60vw':'80vw'}}>Brown Profile provides designer wooden panels curated with international design standards and quality to give a luxurious feel to your space. We have elegantly styled panels to ease the process of making your interior highly exceptional and modern.</text>
    </div>
{button?<div data-aos="fade-up" className='image1'>

</div>:<img alt='Brown Profiles fluted wooden panelling on wall' style={{height:'40vh',width:'100%',marginBottom:50}} src={bg11}/>}
 
<div style={{alignItems:'center',display:'flex',flexDirection:'column',lineHeight:0.75,fontFamily:'Cormorant',padding:button?100:25}}>
  <text  style={{fontSize:button?80:'xx-large',marginRight:button?'60vw':null}}>LET'S</text>
  <text style={{fontSize:button?80:'xx-large',marginRight:button?'40vw':null}}>WORK</text>
  <text style={{fontSize:button?80:'xx-large',}}>TOGETHER</text>
    <img alt='connect wording' style={{height:button?150:50,}} src={connect}/>
    <div data-aos="fade-up" style={{display:'flex',flexDirection:'row',fontFamily:'Montserrat',fontSize:12,fontWeight:'bold',marginTop:25}}>
  
    <p  className='underline-hover-effect' style={{marginRight:button?25:10}}>SEND US A MESSAGE</p>

    <p  className='underline-hover-effect'  style={{marginLeft:button?25:10}}>CALL +91 9030777222  </p>
    </div>
</div>
<Footer/>
    </div>
  );
}

export default Home;