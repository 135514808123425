
import './Contact.css';
import React, { useState, useEffect, } from 'react'
import {FaHome} from 'react-icons/fa';
import { IoCall } from "react-icons/io5";
import {AiOutlineMail} from "react-icons/ai";
import image from "../Assets/bg.jpg";
import  Footer  from "../Footer.js";
import { Helmet } from 'react-helmet';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


function Contact() {

    const [button,setButton] = useState(true);

    const [sq,setSq] = useState('')
    const [wood,setWood] = useState('')
    const [thick,setThick] = useState('')
    const [final,setFinal] = useState('')
    const [error,setError] = useState('')

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);



const calc = () =>{

  let siz = /^[0-9]*$/;
  if (siz.test(sq) === false || sq === '') {
    setError('Please enter valid size');
    return;
  }


  if (!thick) {
    setError('Please select thickness');
    return;
  }

  if (!wood) {
    setError('Please select wood type');
    return;
  }

  if(thick==8){
    if(wood=='RO' || wood=='WA' || wood=='AC' || wood=='S' || wood=='SB'){
    setFinal(sq*750)}
    if(wood=='WO'){
      setFinal(sq*850)}
      if(wood=='AW'){
        setFinal(sq*1450)}
        if(wood=='WW'){
          setFinal(sq*1150)}
          if(wood=='BT'){
            setFinal(sq*1600)}
            if(wood=='IT'){
              setFinal(sq*1200)}
  }
  else{
    if(wood=='RO' || wood=='WA' || wood=='AC' || wood=='S' || wood=='SB'){
      setFinal(sq*1125)}
      if(wood=='WO'){
        setFinal(sq*1275)}
        if(wood=='AW'){
          setFinal(sq*2175)}
          if(wood=='WW'){
            setFinal(sq*1725)}
            if(wood=='BT'){
              setFinal(sq*2500)}
              if(wood=='IT'){
                setFinal(sq*1800)}
              }
}

  return (
    <div style={{backgroundColor:'#f9f5ef',}}>
      <Helmet>
      <title>Contact Us - Brown Profiles</title>
<meta name="description" content="If you have any questions about Brown Profiles® or would like us to be involved with your next project, please contact us via email or phone." />
      </Helmet>
         <title>Brown Profiles | CONTACT US </title>
       <div style={{paddingTop:150,display:'flex',flexDirection:'column',alignItems:'center',paddingBottom:50}}>
       <text style={{fontSize:button?'5vh':'3.5vh',fontFamily:'Cormorant'}}>Get price estimate</text>
        <text style={{width:button?'60vw':'70vw',color:'#1d1c18',textAlign:'center',color:'#C19A5B'}}>Fill in a few details about your requirement for an instant quote.</text>
      <div style={{display:'flex',flexDirection:button?'row':'column',paddingTop:button?70:50}}>
        <Card style={{width:button?'70vw':'85vw',backgroundColor:'#f9f5ef',padding:30}}>
            <div style={{display:'flex',flexDirection:'column',fontSize:14}}>
            <text style={{letterSpacing:0,fontFamily:'montserrat',color:'grey'}}>Size Requirement (in sqft)</text>
            <TextField onChange={(e)=>{setSq(e.target.value);setFinal('');setError('')}} style={{}} id="standard-basic" variant="standard" />
            <text style={{letterSpacing:0,fontFamily:'montserrat',color:'grey',marginTop:20}}>Select Thickness</text>
            <div style={{display:'flex',marginTop:10}}>
            <text onClick={()=>{setThick(8);setFinal('');setError('')}} style={{border:'1px solid grey',borderRadius:5,width:70,height:30,textAlign:'center',paddingTop:3,cursor:'pointer',color:thick==8?'white':'',backgroundColor:thick==8?'#80694f':''}}>12 mm</text>
            <text onClick={()=>{setThick(16);setFinal('');setError('')}} style={{border:'1px solid grey',borderRadius:5,width:70,height:30,marginLeft:25,textAlign:'center',paddingTop:3,cursor:'pointer',color:thick==16?'white':'',backgroundColor:thick==16?'#80694f':''}}>16 mm</text>
            </div>
            <text style={{letterSpacing:0,fontFamily:'montserrat',color:'grey',marginTop:20}}>Select Wood Species</text>
            <FormControl variant="standard">
        <Select onChange={(e)=>{setWood(e.target.value);setFinal('');setError('')}}>
          <MenuItem value={'WO'}>White Oak</MenuItem>
          <MenuItem value={'RO'}>Red Oak</MenuItem>
          <MenuItem value={'WA'}>White Ash</MenuItem>
          <MenuItem value={'SB'}>Steem Beech</MenuItem>
          <MenuItem value={"AC"}>American Cherry</MenuItem>
          <MenuItem value={"S"}>Syncamore</MenuItem>
          <MenuItem value={'AW'}>American Walnut</MenuItem>
          <MenuItem value={'WW'}>Wenge Wood</MenuItem>
          <MenuItem value={'IT'}>Indian Teak</MenuItem>
          <MenuItem value={'BT'}>Burma Teak</MenuItem>
          </Select>
      </FormControl>
      <text onClick={()=>calc()} style={{border:'1px solid #80694f',borderRadius:5,width:150,height:40,textAlign:'center',marginTop:30,paddingTop:8,backgroundColor:'#80694f',color:"white",cursor:'pointer'}}>Calculate</text>
      {error?<text style={{color:'#c4391d',paddingTop:5}}>{error}</text>:null}
      {final?<div style={{display:'flex',flexDirection:'column'}}><text style={{color:'#7b6c5b', fontSize:'x-large',marginTop:10}}>₹ {final}/-</text>
      <text style={{paddingTop:5}}>Prices are exclusive of all taxes*</text>
      </div>:null}
            </div>

    </Card>
      </div>
    </div>

    <div style={{width:'100%',height:button?'55vh':'40vh',backgroundColor:'#F0E7D8',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',fontFamily:'montserrat',fontSize:12,textAlign:'center'}}>
  <text style={{fontFamily:'Cormorant',letterSpacing:1,fontSize:button?'x-large':'2.5vh',}}>THANK YOU</text>
  <text style={{width:button?'60%':'80%',marginTop:5}}>If you have any questions or require further information, please do not hesitate to contact us.</text>
  <a href="mailto:info@brownprofiles.com" ><AiOutlineMail style={{marginTop:20,marginBottom:5,height:18,width:18,color:'black'}} /></a>
 <text>Email Us</text>
  </div>

  <Footer/>
    </div>
  );
}

export default Contact;