
import './Contact.css';
import React, { useState, useEffect, } from 'react'
import oakley_fluted from "../Assets/oakley fluted panel.png";
import easton_fluted from "../Assets/easton fluted panel.png";
import delta_fluted from "../Assets/delta fluted panel.png";
import zion_fluted from "../Assets/zion fluted panel.png";
import cooper_fluted from "../Assets/copper fluted panel.png";
import edison_fluted from "../Assets/edison fluted panel.png";
import cove_fluted from "../Assets/cove fluted panel.png";
import oxford_fluted from "../Assets/oxford fluted panel.png";
import bianca_fluted from "../Assets/bianca fluted panel.png";
import brentley_fluted from "../Assets/brentley fluted panel.png";
import sacha_fluted from "../Assets/sacha fluted panel.png";
import sublime_fluted from "../Assets/sublime fluted panel.png";
import sorrento_fluted from "../Assets/sorrento fluted panel.png";
import chevron_fluted from "../Assets/chevron fluted panel.png";
import Aos from 'aos';

import 'aos/dist/aos.css'; 
import  Footer  from "../Footer.js";
import { Helmet } from 'react-helmet';



function Fluted() {

    const [button,setButton] = useState(true);


   

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);

  useEffect(()=>{
    Aos.init({duration:1000});
  },[]);




  return (
    <div style={{backgroundColor:'#f9f5ef',}}>
<Helmet>
<title>Fluted Wooden Collection - Brown Profiles</title>
<meta name="description" content="A timeless collection of fluted wooden panels designed to provide a plush and opulent feel to your space." />
</Helmet>
<div className={button?'banner':null}>


</div>
          <title>Brown Profiles | FLUTED PANELS | TIMELESS COLLECTION</title>
       <div style={{paddingTop:150,display:'flex',flexDirection:'column',alignItems:'center',paddingBottom:button?100:50}}>
<div style={{display:'flex',flexDirection:'column',alignItems:'center',fontFamily:'Cormorant',lineHeight:.75}}>
<text style={{fontSize:button?'xxx-large':'xx-large',}}>THE</text>
        <text style={{fontSize:button?'xxx-large':'xx-large'}}>TIMELESS</text>
         <text style={{fontSize:button?'xxx-large':'xx-large',}}>COLLECTION</text>
     
</div>
        <text style={{width:'60vw',color:'#1d1c18',textAlign:'center',paddingTop:30}}>A pure love of wood, an art of nature and a passion for working with like-minded designers have helped us build a unique brand, <strong>Brown Profiles®</strong>.</text>
    
    </div>
    <div data-aos="zoom-in"  style={{display:'flex',flexDirection:button?'row':'column',justifyContent:'space-evenly',fontFamily:'Montserrat',letterSpacing:6}}>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <img alt='Biance fluted wooden panel' style={{width:button?'25vw':'90vw',height:button?'15vw':'50vw'}} src={bianca_fluted}/>
      <text style={{fontSize:'xx-large',marginTop:20,}}>BIANCA</text>
  
      </div>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <img alt='Easton fluted wooden panel' style={{width:button?'25vw':'90vw',height:button?'15vw':'50vw'}} src={easton_fluted}/>
      <text style={{fontSize:'xx-large',marginTop:20}}>EASTON</text>
      </div>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <img  alt='Delta fluted wooden panel' style={{width:button?'25vw':'90vw',height:button?'15vw':'50vw'}} src={delta_fluted}/>
      <text style={{fontSize:'xx-large',marginTop:20}}>DELTA</text>
      </div>
  </div>
  <div data-aos="zoom-in"  style={{display:'flex',flexDirection:button?'row':'column',justifyContent:'space-evenly',fontFamily:'montserrat',letterSpacing:6,marginTop:20}}>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <img alt='Zion fluted wooden panel' style={{width:button?'25vw':'90vw',height:button?'15vw':'50vw'}} src={zion_fluted}/>
      <text style={{fontSize:'xx-large',marginTop:20,}}>ZION</text>

      </div>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <img alt='Cooper fluted wooden panel' style={{width:button?'25vw':'90vw',height:button?'15vw':'50vw'}} src={cooper_fluted}/>
      <text style={{fontSize:'xx-large',marginTop:20}}>COOPER</text>
      </div>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <img alt='Edison fluted wooden panel' style={{width:button?'25vw':'90vw',height:button?'15vw':'50vw'}} src={edison_fluted}/>
      <text style={{fontSize:'xx-large',marginTop:20}}>EDISON</text>
      </div>
  </div>

  <div data-aos="zoom-in"  style={{display:'flex',flexDirection:button?'row':'column',justifyContent:'space-evenly',fontFamily:'montserrat',letterSpacing:6,marginTop:20}}>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <img alt='Cove fluted wooden panel' style={{width:button?'25vw':'90vw',height:button?'15vw':'50vw'}} src={cove_fluted}/>
      <text style={{fontSize:'xx-large',marginTop:20}}>COVE</text>

      </div>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <img alt='Oxford fluted wooden panel' style={{width:button?'25vw':'90vw',height:button?'15vw':'50vw'}} src={oxford_fluted}/>
      <text style={{fontSize:'xx-large',marginTop:20}}>OXFORD</text>
      </div>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <img alt='Oakley fluted wooden panel' style={{width:button?'25vw':'90vw',height:button?'15vw':'50vw'}} src={oakley_fluted}/>
      <text style={{fontSize:'xx-large',marginTop:20}}>OAKLEY</text>

      </div>
  </div>
  <div data-aos="zoom-in"  style={{display:'flex',flexDirection:button?'row':'column',justifyContent:'space-evenly',fontFamily:'montserrat',letterSpacing:6,marginTop:20}}>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <img alt='Sublime fluted wooden panel' style={{width:button?'25vw':'90vw',height:button?'15vw':'50vw'}} src={sublime_fluted}/>
      <text style={{fontSize:'xx-large',marginTop:20}}>SUBLIME</text>

      </div>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <img alt='Sacha wooden panel' style={{width:button?'25vw':'90vw',height:button?'15vw':'50vw'}} src={sacha_fluted}/>
      <text style={{fontSize:'xx-large',marginTop:20}}>SACHA</text>
      </div>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <img alt='Brentley fluted wooden panel' style={{width:button?'25vw':'90vw',height:button?'15vw':'50vw'}} src={brentley_fluted}/>
      <text style={{fontSize:'xx-large',marginTop:20}}>BRENTLEY</text>

      </div>
  </div>
  <div data-aos="zoom-in"  style={{display:'flex',flexDirection:button?'row':'column',justifyContent:'space-evenly',fontFamily:'montserrat',letterSpacing:6,margin:30}}>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <img alt='Sorrento fluted wooden panel' style={{width:button?'25vw':'90vw',height:button?'15vw':'50vw'}} src={sorrento_fluted}/>
      <text style={{fontSize:'xx-large',marginTop:20}}>SORRENTO</text>

      </div>
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <img alt='Chevron fluted wooden panel' style={{width:button?'25vw':'90vw',height:button?'15vw':'50vw'}} src={chevron_fluted}/>
      <text style={{fontSize:'xx-large',marginTop:20}}>CHEVRON</text>

      </div>
  </div>

  <div style={{textAlign:'center',padding:100}}>
  <text style={{textAlign:'center',fontFamily:'montserrat',letterSpacing:3,fontSize:12}}>CURATED BY BROWN PROFILES</text>
  </div>
  <div style={{width:'100%',height:button?'40vh':'25vh',backgroundColor:'#F0E7D8',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
  <text style={{fontFamily:'Cormorant',letterSpacing:3,fontSize:button?'large':'small',}}>AVAILABLE IN MULTIPLE SHADES</text>
  </div>
  <Footer/>
    </div>
  );
}

export default Fluted;