import './Footer.css';
import React, { useState, useEffect, } from 'react'
import log1 from "./Assets/logo.png";


const Footer = () => {

    const [button,setButton] = useState(true);

    const showButton = () =>{
      if(window.innerWidth <= 960){
        setButton(false);
      }
      else{
        setButton(true);
      }
    }
    
    useEffect(()=>{
      showButton();
    },[]);
      window.addEventListener('resize',showButton);
    

  return (
      <>
       
       <div style={{color:'white'}} >
    <div className={button?'footer':'footer mobile'} style={{paddingTop:button?100:60,paddingBottom:75}}> 
    <div style={{fontFamily:'Montserrat',display:'flex',flexDirection:'column',paddingLeft:button?'3vw':'5vw',width:button?'40vw':'80vw',marginRight:'5vw'}}>
    <text style={{fontFamily:'Cormorant',fontSize:button?24:20}}>Brown Profiles Pvt. Ltd.</text>
    <p1 style={{fontSize:button?15:12,paddingTop:button?25:15,}}>Brown profile proudly introduces rich shades of solid and natural textures of wood for all your interior needs.</p1>

    </div>
    <div style={{display:'flex',marginLeft:button?'10vw':'5vw',flexDirection:button?'row':'column',}}>
    <div style={{display:'flex',flexDirection:'column',fontFamily:'Montserrat',width:button?'15vw':'80vw',marginTop:button?0:35}}>
    <h4 style={{paddingBottom:button?25:15,fontFamily:'Cormorant',fontSize:button?24:20}}>Quick Links</h4>
 <a style={{fontSize:button?15:12,}} className='link' href='/'> Home</a>
 <a style={{fontSize:button?15:12,}} className='link' href='/fluted'>Fluted </a>
 <a style={{fontSize:button?15:12,}} className='link' href='/about'>About Us</a>
 <a style={{fontSize:button?15:12,}} className='link' href='/contact'>Contact Us</a>
    </div>

    <div style={{display:'flex',flexDirection:'column',fontFamily:'Montserrat',width:button?'25vw':'80vw',marginTop:button?0:35}}>
    <h4 style={{paddingBottom:button?25:15,fontFamily:'Cormorant',fontSize:button?24:20}}>Visit Us</h4>
    <p1 style={{paddingBottom:10,fontSize:button?15:12}}>Viswakarma ML Furniture, Plot No. CFC 1/A, Road No. 10, Kattedan, Hyderabad, 500077</p1>
           <a style={{fontSize:button?15:12,}} href='tel:+919030777222' className='link'>+91 9030777222</a>    
           <a style={{fontSize:button?15:12,}} href="mailto:info@brownprofiles.com" className='link'>info@brownprofiles.com</a>
    </div>
    </div>
    </div>
    <div style={{height:60,width:'100%',backgroundColor:'#f9f5ef',alignItems:'center',display:'flex',justifyContent:'center',fontSize:button?'medium':'small',fontFamily:'PT Sans',color:'black'}}>
      © copyright {new Date().getFullYear()} brownprofiles.com, All Rights Reserved
    </div>
    </div>
  </>
  );
};

export default Footer;