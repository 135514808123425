import './App.css';
import React, { useState, useEffect,lazy,Suspense } from 'react'
import  Navigation  from "./Navbar.js";

import { Switch, Route,BrowserRouter } from "react-router-dom";
import Fluted from './Components/Fluted';
import Price from './Components/Price';
import Home from './Components/Home';
import { Helmet } from 'react-helmet';

const lazyAbout = lazy(()=>import('./Components/About'))
const lazyContact = lazy(()=>import('./Components/Contact'))

const  App=()=> {

const [button,setButton] = useState(true);

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);

  return <div style={{flex:1,}}>  
<BrowserRouter>
<Navigation/>
<Helmet>
<title>Brown Profiles | Fluted Wooden Panels | Wall Paneling</title>
<meta name="description" content="The Brown Profiles introduces perfectly curated fluted wooden panels to make your space luxurious and worthy." />
<meta name="keywords" content="Wall Panels, Wooden Panel, Fluted Panel, Brown Profiles"/>
</Helmet>
<Switch>

<Suspense fallback='loading...'>
<Route path='/' exact component={Home} />
<Route path='/contact' component={lazyContact} />
<Route path='/about'  component={lazyAbout} />
<Route path='/fluted' component={Fluted} />
<Route path='/price' component={Price} />
</Suspense>
</Switch>

</BrowserRouter>
  </div>
}

export default App;
